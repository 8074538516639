/* global MoveTo */

(() => {
  const moveTo = new MoveTo();

  function onPressTriggerClick(e) {
    e.preventDefault();

    const href = this.getAttribute('href');
    const target =
      href && href !== '#' ? document.getElementById(href.substring(1)) : document.body;

    moveTo.move(target, {
      tolerance: window.innerWidth > 1023 ? 100 : 20,
    });
  }

  const triggers = document.getElementsByClassName('js-moveto-press-trigger');

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < triggers.length; i++) {
    triggers[i].addEventListener('click', onPressTriggerClick);
  }
})();
