(() => {
  /*
   * Sticky Header
   * This feature works only on screens that are higher than 1024 pixels
   */
  const stickyHeader = document.getElementById('header');
  let isStickyHeaderInitialized = false;

  /**
   * Sticky element initialize function
   * @param  {HTMLElement} elm Header html dom element
   */
  function stickyElementInit(elm) {
    const breakPoint = 200;
    let sticky = false;

    if (window.pageYOffset > breakPoint) {
      elm.classList.add('is-sticky');
      sticky = true;
    }

    window.onscroll = () => {
      if (window.pageYOffset > breakPoint) {
        if (!sticky) {
          elm.classList.add('is-sticky');
          sticky = true;
        }
      } else if (sticky) {
        elm.classList.remove('is-sticky');
        sticky = false;
      }
    };
  }

  if (window.innerWidth > 1023 && Boolean(stickyHeader)) {
    stickyElementInit(stickyHeader);
    isStickyHeaderInitialized = true;
  }

  if (window.innerWidth < 1025 && window.innerWidth > 767 && Boolean(stickyHeader)) {
    window.addEventListener('orientationchange', () => {
      if (!isStickyHeaderInitialized) {
        stickyElementInit(stickyHeader);
        isStickyHeaderInitialized = true;
      }
    });
  }
})();
