/* global $ SpriteSpin */

(() => {
  const spritespins = document.getElementsByClassName('js-spritespin');

  function registerPlugin(element) {
    const sourceArray = element.getAttribute('data-source-array');
    const frameStart = parseInt(element.getAttribute('data-frame-start'), 10);
    const frameEnd = parseInt(element.getAttribute('data-frame-end'), 10);

    const width = parseInt(element.getAttribute('width'), 10);
    const height = parseInt(element.getAttribute('height'), 10);
    const { clientWidth } = element;

    const canvasWidth = clientWidth < width ? clientWidth : width;
    const canvasHeight = clientWidth < width ? clientWidth * height / width : height;

    $(element).spritespin({
      // generate an array of image urls.
      frameTime: 400,
      // this is a helper function that takes a {frame} placeholder
      source: SpriteSpin.sourceArray(sourceArray, {
        // this ramge of numbers is interpolated into the {frame} placeholder
        frame: [frameStart, frameEnd],
        // the frame placeholder will be padded with leading '0' up to the number of 'digits'
        digits: 1,
      }),
      // Specify the display width and height of the frame.
      // Optionally the size of the container can be defined with CSS.
      width: canvasWidth,
      height: canvasHeight,
      // Sense controls the direction and speed of the animation for mouse/touch interactions.
      // Here a negative value is chosen to invert the rotation, so the animation 'follows' the drag direction.
      // Values towards 0 will slow the animation down.
      // sense: -1
    });
  }

  /* eslint-disable no-plusplus */
  for (let i = 0; i < spritespins.length; i++) {
    registerPlugin(spritespins[i]);
  }
})();
