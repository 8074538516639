/* global Tooltip */

(() => {
  const tooltipTriggers = document.getElementsByClassName('js-feature-tooltip-trigger');

  const instances = new Map();

  function registerTooltip(trigger) {
    let placement = trigger.getAttribute('data-tooltip-placement');
    let content = trigger.getAttribute('data-tooltip-text-content');
    const contentId = trigger.getAttribute('data-tooltip-content-id');

    if (contentId) {
      content = document.getElementById(contentId).innerHTML;
    }

    if (window.innerWidth < 768) {
      placement = null;
    }

    instances.set(
      trigger,
      new Tooltip(trigger, {
        placement: placement || 'auto',
        title: content,
        trigger: 'manual',
        html: !!contentId,
        template: `
        <div class="c-tooltip tooltip" role="tooltip">
          <div class="c-tooltip__inner tooltip-inner"></div>
          <span class="c-tooltip__arrow tooltip-arrow" aria-hidden="true"></span>
        </div>
      `,
      }),
    );
  }

  function onTriggerClick() {
    const tooltip = instances.get(this);

    if (!tooltip) {
      return;
    }

    // eslint-disable-next-line no-underscore-dangle
    if (tooltip._isOpen) {
      this.classList.remove('is-expanded');
      this.parentNode.classList.remove('is-active');
      tooltip.hide();
      return;
    }

    this.classList.add('is-expanded');
    this.parentNode.classList.add('is-active');
    tooltip.show();
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < tooltipTriggers.length; i++) {
    registerTooltip(tooltipTriggers[i]);
    tooltipTriggers[i].addEventListener('click', onTriggerClick);
  }
})();
