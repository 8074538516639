/* global $, MicroModal  */
(() => {
  $(document.getElementsByClassName('js-slider-common')).slick({
    infinite: false,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
  });

  $(document.getElementsByClassName('js-slider-center-mode')).slick({
    arrows: false,
    slidesToShow: 2,
    centerMode: true,
    // variableWidth: true,
    autoplay: true,
    autoplaySpeed: 5000,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          // centerMode: false,
          slidesToShow: 1,
          // variableWidth: false,
        },
      },
    ],
  });

  MicroModal.init();

  // For testing image areas.
  // $('.c-feature-map').on('click', function(e) {
  //   const x = (e.offsetX / $(this).width() * 100).toFixed(2);
  //   const y = (e.offsetY / $(this).height() * 100).toFixed(2);
  //   const style = `left: ${x}%; top: ${y}%;`;
  //   console.log(style);
  // });
})();
