/* global vanillaTextMask */

(() => {
  const phoneMaskNodes = document.getElementsByClassName('js-input-mask-phone');
  const phoneMask = [
    '+',
    '9',
    '0',
    ' ',
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
  ];

  /* eslint-disable no-plusplus */
  for (let i = 0; i < phoneMaskNodes.length; i++) {
    vanillaTextMask.maskInput({
      inputElement: phoneMaskNodes[i],
      mask: phoneMask,
      guide: false,
    });
  }
})();
