(() => {
  const hamburger = document.getElementById('header-hamburger');
  const headerNav = document.getElementById('header-nav');
  const dropdownTriggers = document.getElementsByClassName('js-header-dropdown-trigger');
  const mobileMenuCloseButton = document.getElementById('mobile-menu-close-button');

  if (!hamburger || !headerNav) {
    return;
  }

  function closeDropdown() {
    const lastActive = headerNav.getElementsByClassName('is-expanded')[0];
    if (!lastActive) {
      return;
    }

    lastActive.classList.remove('is-expanded');
  }

  function onHamburgerClick() {
    headerNav.classList.add('is-mobile-menu-active');
  }

  function onHeaderNavClick(e) {
    if (e.target !== headerNav) {
      return;
    }

    closeDropdown();
    headerNav.classList.remove('is-mobile-menu-active');
  }

  function onDropdownTriggerClick(e) {
    if (window.innerWidth < 1200) {
      e.preventDefault();
    }
    const lastActive = headerNav.getElementsByClassName('is-expanded')[0];
    closeDropdown();

    if (lastActive === this.parentNode) {
      return;
    }

    this.classList.add('is-mobile-menu-active');
    this.parentNode.classList.add('is-expanded');
  }

  function onCloseButtonClick() {
    closeDropdown();

    headerNav.classList.remove('is-mobile-menu-active');
  }

  headerNav.style.display = 'block';
  hamburger.addEventListener('click', onHamburgerClick);
  headerNav.addEventListener('click', onHeaderNavClick);
  mobileMenuCloseButton.addEventListener('click', onCloseButtonClick);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < dropdownTriggers.length; i++) {
    dropdownTriggers[i].addEventListener('click', onDropdownTriggerClick);
  }
})();
