/* global Validetta */

(() => {
  const basicFormNodes = document.getElementsByClassName('js-validation-basic');

  const language = {
    tr: {
      required: 'Bu alanı doldurmanız gerekli. Lütfen kontrol ediniz.',
      email: 'Eposta adresiniz geçersiz görünüyor. Lütfen kontrol ediniz.',
      number: 'Bu alana sadece rakam girişi yapabilirsiniz.',
      maxLength: 'En fazla {count} karakter girebilirsiniz !',
      minLength: 'En az {count} karakter girmelisiniz!',
      maxChecked: 'En fazla {count} seçim yapabilirsiniz. Lütfen kontrol ediniz.',
      minChecked: 'En az {count} seçim yapmalısınız. Lütfen kontrol ediniz.',
      maxSelected: 'En fazla {count} seçim yapabilirsiniz. Lütfen kontrol ediniz.',
      minSelected: 'En az {count} seçim yapmalısınız. Lütfen kontrol ediniz.',
      notEqual: 'Alanlar birbiriyle oyuşmuyor. Lütfen kontrol ediniz',
      different: 'Alanlar birbirlerinden farklı olmalı.',
      creditCard: 'Kredi kartı numarası geçersiz. Lütfen kontrol ediniz.',
      phone: 'Lütfen geçerli bir telefon numarası giriniz.',
    },
    en: {
      required: 'This field is required. Please be sure to check.',
      email: 'Your E-mail address appears to be invalid. Please be sure to check.',
      number: 'You can enter only numbers in this field.',
      maxLength: 'Maximum {count} characters allowed!',
      minLength: 'Minimum {count} characters allowed!',
      maxChecked: 'Maximum {count} options allowed. Please be sure to check.',
      minChecked: 'Please select minimum {count} options.',
      maxSelected: 'Maximum {count} selection allowed. Please be sure to check.',
      minSelected: 'Minimum {count} selection allowed. Please be sure to check.',
      notEqual: 'Fields do not match. Please be sure to check.',
      different: 'Fields cannot be the same as each other',
      creditCard: 'Invalid credit card number. Please be sure to check.',
      phone: 'Please enter valid phone number.',
    },
  };

  let { lang } = document.documentElement;

  if (!(lang in language)) {
    lang = 'tr';
  }

  const basicForms = [];
  const basicOptions = {
    display: 'inline',
    realTime: true,
    errorTemplateClass: 'validetta-error',
    errorClass: 'is-invalid',
    validClass: 'is-valid',
    validators: {
      pattern: {
        phone: {
          pattern: /^\+90\s\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}$/i,
          errorMessage: language[lang].phone,
        },
      },
    },
  };

  /* eslint-disable no-plusplus */
  for (let i = 0; i < basicFormNodes.length; i++) {
    basicForms.push(new Validetta(basicFormNodes[i], basicOptions, language[lang]));
  }
})();
