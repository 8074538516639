(() => {
  const fileInputs = document.getElementsByClassName('js-form-file-control-input');

  function onFileInputChange() {
    const fileName = this.value.split('\\').pop();

    const placeholder = this.parentNode.getElementsByClassName(
      'js-form-control-mask-placeholder',
    )[0];

    if (!placeholder || !fileName) {
      return;
    }

    placeholder.innerText = fileName;
  }

  /* eslint-disable no-plusplus */
  for (let i = 0; i < fileInputs.length; i++) {
    fileInputs[i].addEventListener('change', onFileInputChange);
    onFileInputChange.apply(fileInputs[i]);
  }
})();
