/* global $ */

(() => {
  const tabTriggers = document.getElementsByClassName('js-tabs-trigger');

  if (!tabTriggers.length) {
    return;
  }

  function resetActive(element) {
    const trigger = element.getElementsByClassName('js-tabs-trigger')[0];

    if (!trigger) {
      return;
    }

    trigger.setAttribute('aria-selected', false);

    const tabContent = document.getElementById(trigger.getAttribute('href').substr(1));
    const tabImage = document.getElementById(trigger.getAttribute('data-tab-image'));
    element.classList.remove('is-active');

    if (tabContent) {
      tabContent.setAttribute('hidden', true);
      tabContent.setAttribute('aria-hidden', true);
    }

    if (tabImage) {
      tabImage.setAttribute('hidden', true);
    }
  }

  function activateTab(trigger) {
    const tabContent = document.getElementById(trigger.getAttribute('href').substr(1));
    const tabImage = document.getElementById(trigger.getAttribute('data-tab-image'));

    if (!tabContent) {
      return;
    }

    trigger.parentNode.classList.add('is-active');
    trigger.setAttribute('aria-selected', true);

    tabContent.removeAttribute('hidden');
    tabContent.setAttribute('aria-hidden', false);

    if (tabImage) {
      tabImage.removeAttribute('hidden');
    }
  }

  function onTabTriggerClick(e) {
    e.preventDefault();

    const activeTab = $(this)
      .parents('.js-tabs')
      .find('.is-active')[0];

    if (this.parentNode === activeTab) {
      return;
    }

    resetActive(activeTab);
    activateTab(this);
  }

  /* eslint-disable no-plusplus */
  for (let i = 0; i < tabTriggers.length; i++) {
    tabTriggers[i].addEventListener('click', onTabTriggerClick);
  }
})();
