(() => {
  /*
   *  Counter
   */
  function Counter(el) {
    this.el = el;
    this.currentValue = 0;
    this.countTo = parseInt(el.getAttribute('data-count-to'), 10);
    this.step = parseInt(el.getAttribute('data-step'), 10) || 1;
    this.fps = 60;
    this.interval = 1000 / this.fps;
    this.seperator = el.getAttribute('data-seperator');
    this.triggerTolerance = parseInt(el.getAttribute('data-trigger-tolerance'), 10) || 0;
    this.triggerTolerance -= window.outerHeight;
    this.offset = window.getOffsetSum(el);

    if (window.innerWidth > 1023) {
      this.init();
    } else {
      this.el.innerHTML = this.countTo;
    }
  }

  Counter.prototype = {
    constructor: Counter,

    seperateNumber(n) {
      if (typeof this.seperator !== 'string' || n.length < 4) {
        return n;
      }
      return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, this.seperator);
    },

    init() {
      let active = false;

      if (window.pageYOffset > this.offset.top + this.triggerTolerance) {
        return this.run();
      }
      const onscroll = () => {
        if (active) {
          return;
        }
        if (window.pageYOffset > this.offset.top + this.triggerTolerance) {
          this.run();
          active = true;
          window.removeEventListener('scroll', onscroll);
        }
      };
      window.addEventListener('scroll', onscroll);
      return this;
    },

    run() {
      const draw = () => {
        this.el.innerHTML = this.seperateNumber(this.currentValue);
        this.currentValue = this.currentValue + this.step;
        if (this.currentValue < this.countTo + 1) {
          setTimeout(() => window.requestAnimationFrame(draw), this.interval);
        } else {
          this.el.innerHTML = this.seperateNumber(this.countTo);
        }
      };
      return draw();
    },

    refresh() {
      this.currentValue = 0;
      return this.run();
    },
  };
  const counters = document.getElementsByClassName('js-counter');
  let i = 0;
  const instances = [];
  while (i < counters.length) {
    instances.push(new Counter(counters[i]));
    // eslint-disable-next-line no-plusplus
    i++;
  }
  window.Counter = Counter;
})();
