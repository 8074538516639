/* global inView */

(() => {
  function onEnter(el) {
    el.classList.remove('is-not-in-viewport');
    el.classList.add('is-in-viewport');
  }

  inView('.js-timeline-card-in-view').on('enter', onEnter);
})();
