(() => {
  let lastTime = 0;
  const vendors = ['ms', 'moz', 'webkit', 'o'];

  // eslint-disable-next-line no-plusplus
  for (let x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
    window.requestAnimationFrame = window[`${vendors[x]}RequestAnimationFrame`];
    window.cancelAnimationFrame =
      window[`${vendors[x]}CancelAnimationFrame`] ||
      window[`${vendors[x]}CancelRequestAnimationFrame`];
  }

  if (!window.requestAnimationFrame)
    window.requestAnimationFrame = callback => {
      const currTime = new Date().getTime();
      const timeToCall = Math.max(0, 16 - (currTime - lastTime));

      const id = window.setTimeout(() => {
        callback(currTime + timeToCall);
      }, timeToCall);

      lastTime = currTime + timeToCall;
      return id;
    };

  if (!window.cancelAnimationFrame)
    window.cancelAnimationFrame = id => {
      clearTimeout(id);
    };
})();

window.getOffsetSum = elem => {
  let top = 0;
  let left = 0;

  while (elem) {
    top += parseInt(elem.offsetTop, 10);
    left += parseInt(elem.offsetLeft, 10);
    // eslint-disable-next-line no-param-reassign
    elem = elem.offsetParent;
  }

  return {
    top,
    left,
  };
};
