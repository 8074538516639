(() => {
  const select = document.getElementById('subheader-menu-select');

  if (!select) {
    return;
  }

  function onSelectChange() {
    window.location = this.options[this.selectedIndex].getAttribute('data-redirect-url');
  }

  select.addEventListener('change', onSelectChange);
})();
